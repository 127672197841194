<template>
   <div>
     <v-row>
      <v-col cols=3>
        <br>
              </v-col>
       <v-col cols=12>
     <apexchart width="600" height="600" type="bar" :options="chartOptions" :series="series"></apexchart>
              </v-col>    

     </v-row>
   </div>
</template>

<script>
import axios from 'axios';

export default {


  name: 'BidAskChart',
    props: ['dropdownSelected'],

   data: function() {
      return {
        APIData: undefined,
        chartOptions: {
          dataLabels: {
            textAnchor: 'middle',
            formatter: v => `${(v/1000).toFixed(1)}k`,

            style: {
              colors: ['#000']
            }
          },
          chart: {
            id: 'bidAskChart',
                    toolbar: {
                show: false,
            },
          },
          legend: {
            position: "top"
          },
            

          xaxis: {
            categories: [],
            title: {
              text: "Order Book Positions at Time"
            },


          },
          yaxis: {
            title: {
              text: "Cumulative Order Value ($)"
            },
            decimalsInFloat: 0,
            tickAmount: 30,
            min: 0,
            max: 30000
          }
        },
        series: [{
          name: ["bids"],
          data: []
        }, {
          name: ["asks"],
          data: []
        }],
        pairIds: ["cosmos", "evmos", "juno-network", "kujira", "terra-luna-2", "osmosis", "secret", "weth"],
        pairs: ["ATOM/axlUSDC", "EVMOS/axlUSDC", "JUNO/axlUSDC", "KUJI/axlUSDC", "LUNA/axlUSDC", "OSMO/axlUSDC", "SCRT/axlUSDC", "wETH/axlUSDC"],
        selected: "ATOM/axlUSDC"
      }
    },
    async mounted() {
      this.selected = this.dropdownSelected;
      let response = await axios.get("https://a10-kujira-mm.ew.r.appspot.com/tpv");
      this.APIData = response.data;
      console.log(this.selected);
      this.setChart();
    },

      watch: {
        dropdownSelected: function(){
            this.selected = this.dropdownSelected;
            this.setChart();
        }
    },

    methods: {
      setChart: function(){
        let newCategories = [];
        this.series = [{
          name: ["bids"],
          data: []
        }, {
          name: ["asks"],
          data: []
        }]
        let index = this.pairs.indexOf(this.selected);
        let token = this.pairIds[index];
        console.log(this.APIData);
        for(const [date, tpv] of Object.entries(this.APIData)){
          let dateObj = new Date(date);
          if( Object.keys(tpv).includes(token)) {
          
          newCategories.push(dateObj.toLocaleString())
          this.series[0].data.push(parseInt(tpv[token].bids));
          this.series[1].data.push(parseInt(tpv[token].asks));
          console.log(this.chartOptions.xaxis.categories);
          console.log(this.series);
          }
          }
                  this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
                categories: newCategories
                }
            };
      }
    }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
