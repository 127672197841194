<template>
   <div>
     <v-row>
      <v-col cols=3>
        <br>
              </v-col>
       <v-col cols=12>
     <apexchart width="1000" height="600" type="line" :options="chartOptions" :series="series"></apexchart>
              </v-col>    

     </v-row>
   </div>
</template>

<script>
import axios from 'axios';

export default {


  name: 'PositionsChart',
  props: ['dropdownSelected'],
   data: function() {
      return {
        APIData: undefined,
        chartOptions: {
          chart: {
            toolbar: {
                show: false,
            },
            id: 'cumulative-orders'
          },
          legend: {
              position: 'top'
          },
          xaxis: {
            categories: [],
            title: {
              text: "Order Spreads At Snapshot Time"
            },
            labels: {
                rotate: -45,
                rotateAlways: true
            }

          },
          yaxis: {
            title: {
              text: "Cumulative Order Value ($)"
            },
            decimalsInFloat: 0,
            tickAmount: 30,
            min: 0,
            max: 30000
          },
        },
        series: [],
        pairIds: ["cosmos", "evmos", "juno-network", "kujira", "terra-luna-2", "osmosis", "secret", "weth"],
        pairs: ["ATOM/axlUSDC", "EVMOS/axlUSDC", "JUNO/axlUSDC", "KUJI/axlUSDC", "LUNA/axlUSDC", "OSMO/axlUSDC", "SCRT/axlUSDC", "wETH/axlUSDC"],
        selected: "ATOM/axlUSDC"
      }
    },
    async mounted() {
      this.selected = this.dropdownSelected;
      let response = await axios.get("https://a10-kujira-mm.ew.r.appspot.com/positions");
      this.APIData = response.data;
      this.setChart();
    },

    watch: {
        dropdownSelected: function(){
            this.selected = this.dropdownSelected;
            this.setChart();
        }
    },

    methods: {
      setChart: function(){
        let newCategories = [];
        this.series = [];

        let index = this.pairs.indexOf(this.selected);
        let token = this.pairIds[index];
        for(const [date, tokenSpreads] of Object.entries(this.APIData)){
          let dateObj = new Date(date);
          if( Object.keys(tokenSpreads).includes(token)) {
          newCategories.push(dateObj.toLocaleString());
          let tokenData = tokenSpreads[token];
            for(const [spread, amount] of Object.entries(tokenData)){
              let hasSeries = this.series.filter(record => {
                return record.name == `${spread}%`;
              })
              if(hasSeries.length == 0){
                this.series.push({
                  name: `${spread}%`,
                  data: [amount]
                })
              }
              else {
                hasSeries[0].data.push(amount);
              }
            }
          }
        }
        this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
                categories: newCategories
                }
            };
      }
    }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
