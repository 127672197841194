<template>
   <div class="px-5 py-5">
     <v-row>
       <v-col cols=4>
         <h2> Pair </h2>
           <v-select
          :items="pairs"
          v-model="selected"
          label="Solo field"
          dense
          solo
        ></v-select>
       </v-col>
     </v-row>
  <v-row>
     
  <positions-chart :dropdownSelected="selected"/>
      <bid-ask-chart :dropdownSelected="selected" />
  </v-row>

   </div>
</template>

<script>
import PositionsChart from './PositionsChart.vue';
import BidAskChart from './BidAskChart.vue';

export default {
  components: { PositionsChart, BidAskChart },


  name: 'Dashboard',
  data: function() {
    return {
        pairIds: ["cosmos", "evmos", "juno-network", "kujira", "terra-luna-2", "osmosis", "secret", "weth"],
        pairs: ["ATOM/axlUSDC", "EVMOS/axlUSDC", "JUNO/axlUSDC", "KUJI/axlUSDC", "LUNA/axlUSDC", "OSMO/axlUSDC", "SCRT/axlUSDC", "wETH/axlUSDC"],
        selected: "ATOM/axlUSDC"
    }
  }
   


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
